import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SendFormData } from 'interfaces/mail.interface';
import config from 'config';
const api_base = config.MAIL_URL;

const PATH = 'mailing';

export const mailingApi = createApi({
    reducerPath: 'mailingApi',
    refetchOnFocus: false,
    baseQuery: fetchBaseQuery({
        baseUrl: api_base,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: (builder) => ({
        sendFormData: builder.mutation<SendFormData, SendFormData>({
            query: (data) => ({
                url: `${PATH}/sendEmail`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useSendFormDataMutation } = mailingApi;
