// src/config.js
const config = {
    SITE_URL: process.env.REACT_APP_SITE_URL,
    MAIL_URL: process.env.REACT_APP_MAIL_URL,
    API_URL: process.env.REACT_APP_API_URL,
    WP_API_URL: process.env.REACT_APP_WP_API_URL,
    EMAIL_FOLDER: process.env.REACT_APP_EMAIL_FOLDER,
    EMAIL_FROM_SEND: process.env.REACT_APP_EMAIL_FROM_SEND,
    EMAIL_TO_SEND: process.env.REACT_APP_EMAIL_TO_SEND,
    EMAIL_TO_SEND_SUGGUESTIONS: process.env.REACT_APP_EMAIL_TO_SEND_SUGGUESTIONS,
    EMAIL_TEMPLATE: process.env.REACT_APP_EMAIL_TEMPLATE,
    EMAIL_TEMPLATE_SUGGUESTIONS: process.env.REACT_APP_EMAIL_TEMPLATE_SUGGUESTIONS,
};

export default config;
