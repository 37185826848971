import React from 'react';
import { NVInput } from 'components/generals/inputs/nv__input';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactFormSchema } from 'schemas/contact.form.schema';
import { ContactFormInterface } from 'interfaces/contact.form.interface';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import ContactInfo from 'components/generals/forms/contact/contactInfo';
import config from 'config';
import { useSendFormDataMutation } from 'reduxStore/services/mailing.services';

export default function ContactForm() {
    const [sendEmailData] = useSendFormDataMutation();
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<ContactFormInterface>({
        resolver: yupResolver(contactFormSchema),
        shouldFocusError: false,
    });

    const onSubmit: SubmitHandler<ContactFormInterface> = async (data) => {
        console.log(data);

        const formData = {
            folder: config.EMAIL_FOLDER as string,
            templateHTML: config.EMAIL_TEMPLATE as string,
            fromEmail: config.EMAIL_FROM_SEND as string,
            ToEmail: config.EMAIL_TO_SEND as string,
            subject: 'Nuevo Correo del sitio Web Narval',
            name: data.name,
            email: data.email,
            company: data.company,
            message: data.message,
            attachments: [
                {
                    filename: 'logo.png',
                    cid: 'logo@cid',
                },
            ],
        };

        const __sendFormData = await sendEmailData(formData).unwrap();
        console.log('__sendFormData', __sendFormData);
        reset();
        message.success('Message sent successfully', 3);
    };

    return (
        <main className="nv__contact__form">
            <ContactInfo />
            <div className="nv__contact__form__block">
                <div className="contact__form__base">
                    <div className="contact__form__base__container">
                        <img
                            className="contact__form__base__logo"
                            src={`${config.SITE_URL}/assets/logos/logo-white-blue.png`}
                            alt="Narval Logo"
                        />

                        <div className="contact__form__fields">
                            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <NVInput
                                    name="name"
                                    type="text"
                                    placeholder={t('contactForm.form.name')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.name?.message}
                                />

                                <NVInput
                                    name="company"
                                    type="text"
                                    placeholder={t('contactForm.form.company')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.company?.message}
                                />

                                <NVInput
                                    name="email"
                                    type="text"
                                    placeholder={t('contactForm.form.email')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.email?.message}
                                />

                                <NVInput
                                    name="message"
                                    type="text"
                                    placeholder={t('contactForm.form.message')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.message?.message}
                                />

                                <input type="submit" value={t('contactForm.form.submit')} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
